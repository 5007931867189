import { Canvas} from "@react-three/fiber";
import { Environment, Html, OrbitControls} from '@react-three/drei'
import { useRef, useState, useEffect} from "react";

const items = [
  { id:"div5", type: "iframe", title: "embed1", src: "about.html", width: 400, height: 330, position: [0, 0, -20] },
  { id:"div7", type: "iframe", title: "embed2", src: "interviews.html", width: 250, height: 130, position: [20, 0, 0], rotation: [0, -Math.PI / 2, 0] },
  { id:"div1", type: "image", src: "projects/image_manipulation/img/img_1.png", width: 400, height: 200,position: [-25, 0, 25], rotation: [0, Math.PI - Math.PI/4, 0]  },
  { id:"div6",type: "iframe", title: "embed3", src: "works.html", width: 400, height: 520, position: [-20, 0, 0], rotation: [0, Math.PI / 2, 0] },

  { id:"div11", type: "iframe", title: "embed4", src: "projects/blendShape.html", width: 300, height: 200, position: [18, 0, -18], rotation: [0, -Math.PI / 4, 0] },
  { id:"div3", type: "image", src: "projects/image_manipulation/img/img_3.png", width: 400, height: 200, position: [18, 0, 18], rotation: [0, Math.PI + Math.PI/4, 0]  },
  { id:"div2", type: "image", src: "projects/image_manipulation/img/img_2.png", width: 300, height: 600, position: [-18, 0, 18], rotation: [0, Math.PI - Math.PI/4, 0] },
  { id:"div8", type: "iframe", title: "embed5", src: "projects/portrait/portrait.html", width: 400, height: 500, position: [-25, 0, -25], rotation: [0, Math.PI/4, 0] },

  { id:"div9", type: "iframe", title: "embed6", src: "projects/audio_reactive1.html", width: 500, height: 380, position: [0, 0, -32]},
  { id:"div10", type: "iframe", title: "embed7", src: "projects/audio_reactive2.html", width: 500, height: 380, position: [25, 0, -25], rotation: [0, -Math.PI/4, 0] },
  { id:"div11", type: "iframe", title: "embed8", src: "projects/blendShape.html", width: 300, height: 200, position: [32, 0, 0], rotation: [0, -Math.PI/2, 0] },

  { id:"div4", type: "iframe", title: "embed9", src: "projects/image_manipulation/index.html", width: 900, height: 600, position: [0, 0, 32], rotation: [0, Math.PI, 0] },
  { id:"div12", type: "iframe", title: "embed10", src: "contact.html", width: 250, height: 150, position: [-18, 0, -18], rotation: [0, Math.PI/4, 0] },
  { id:"div13", type: "image", src: "projects/portrait/1.png", width: 400, height: 500, position: [-32, 0, 0], rotation: [0, Math.PI / 2, 0]},
  { id:"div14", type: "iframe",title: "embed11", src: "projects/mirrors/index.html", width: 600, height: 480, position: [32, 0, -32], rotation: [0, -Math.PI/4, 0]},
  
  { id:"div15", type: "image", src: "projects/mirrors/img/img1.png", width: 400, height: 380, position: [0, 0, -44]},
  { id:"div16", type: "image",src: "projects/mirrors/img/img2.png", width: 500, height: 380, position: [44, 0, 0], rotation: [0, -Math.PI/2, 0] },
  { id:"div17", type: "image", src: "projects/mirrors/img/img3.png", width: 400, height: 380, position: [-32, 0, -32], rotation: [0, Math.PI/4, 0]  },
  { id:"div18", type: "iframe", title: "embed12", src: "exhibitions.html", width: 400, height: 380, position: [0, 0, 20], rotation: [0, Math.PI, 0] },

  { id:"div19", type: "iframe", title: "embed13", src: "projects/loverz.html", width: 600, height: 330, position: [25, 0, 25], rotation: [0, Math.PI + Math.PI/4, 0]},

];

export default function App() {

  const [selectedItem, setSelectedItem] = useState(null);
  const infoRef = useRef(null);

  const handleClick = (id) => {
    items.forEach(item => {
      const panel = document.getElementById(item.id);
      if (panel) {
        panel.style.display = 'none';
      }
    });
    
    const infoPanel = document.getElementById(id);
    if (infoPanel) {
      infoPanel.style.display = 'block';
    }
  };

  const handleClickOutside = (event) => {

    const isPanelClicked = items.some(item => {
      const panel = document.getElementById(item.id);
      return panel && panel.contains(event.target);
    });
  
    if (!isPanelClicked) {
      items.forEach(item => {
        const panel = document.getElementById(item.id);
        if (panel) {
          panel.style.display = 'none';
        }
      });
    }
  };
  

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { passive: true });
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

return (
  <Canvas gl={{ powerPreference: 'high-performance', antialias: false }} camera={{ position:[200, 100, 200], fov: 20 }} loading={"lazy"}  >
  
      <mesh >
        {/* <gridHelper args={[200,200]} colorGrid="0xff0000" position={[0,-2,0]} />  */}
        {/* <axesHelper size={50} /> */}
      </mesh>
      {/*  const { position, rotation } = item; */}

      {items.map((item, index) => {
        const { position, rotation } = item;
        const isSelected = selectedItem === item.info;

        return (
         
          <Html
              key={index}
              ref={isSelected ? selectedItemRef : null}
              style={{ userSelect: 'none', display:'block' }}
              position={isSelected ? [0, 0, 0] : position} 
              rotation={rotation}
              transform
          >
              {item.type === "iframe" ? (
                  <>
                      <div onClick={() => handleClick(item.id)} style={{ width: item.width, height: item.height, position: 'absolute', top: 0, left: 0, cursor: 'pointer' }} />
                      <iframe title={item.title} width={item.width} height={item.height} src={item.src} loading={'lazy'} />
                  </>
              ) 
              
              : (
                  <img title={item.title} width={item.width} height={item.height} style={{ cursor: 'pointer' }} src={item.src} onClick={() => handleClick(item.id)} />
              )}
          </Html>

      );
  })}
          
      <Environment preset="lobby" />
      <ambientLight intensity={0.1} />
      <directionalLight position={[0, 0, 5]} color="white" />
      <OrbitControls 
      enablePan={false} 
      rotateSpeed={0.5} 
      // enableRotate={true} 
      enableZoom={true} 
      // maxDistance={200} 
      maxDistance={200}
      maxZoom={-50}
      maxPolarAngle={Math.PI*0.55}
      minPolarAngle={Math.PI*0.35}
      autoRotate={true}

      />
    </Canvas>
)
}